export default function() {
    // Mobile navigation (global)
    var nav = document.querySelector('[data-nav]');
    var navTrigger = document.querySelector('[data-nav-trigger]');

    if(navTrigger){
        navTrigger.addEventListener('click', function() {
            navTrigger.classList.toggle('is-active');
            nav.classList.add('drawer--transition');
            nav.classList.toggle('drawer--active');
            document.body.classList.toggle('drawer-active');
        });
    }
}