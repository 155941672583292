import MicroModal from 'micromodal'

export default function() {
    // Modals (global)
    MicroModal.init({
        awaitCloseAnimation: true,
        onClose: modal => stopVideo(modal)
    });

    // Stop videos
    function stopVideo() {
        const iframes = document.querySelectorAll('iframe');

        if (iframes){
            iframes.forEach(function(iframe) {
                iframe.remove();
            });
        }
    }
}