import '../css/main.css'
import setupAccordions from './accordions'
import setupNavigation from './navigation'
import setupModals from './modals'
import setupScrolling from './scroll'
import setupLineClampDetectionForReadMoreButtons from './line-clamp'

/*IMPORTANT INFORMATION
There is no need for listening to the DOMContentLoaded event, scripts are/should be deferred until the document is loaded.
Please don't fill up this file with lots of different functionality. Rather, split them up into their own modules.*/

setupAccordions()
setupNavigation()
setupModals()
setupScrolling()
setupLineClampDetectionForReadMoreButtons()

// Stop videos
function stopVideo() {
    const iframes = document.querySelectorAll('iframe');

    if (iframes){
        iframes.forEach(function(iframe) {
            iframe.remove();
        });
    }
}

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR")
    });
}