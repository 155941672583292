import ScrollOut from 'scroll-out'

export default function() {
    // Scroll effects (global)
    ScrollOut({
        targets: '[data-scroll]',
        once: true,
        threshold: 0.5
    });


    // Scroll effects for paralax (global)
    ScrollOut({
        targets: '[data-parallax]',
        threshold: 0.5,
        cssProps: {
            viewportY: true,
            visibleY: true
        }
    });
}